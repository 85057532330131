var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CbModal",
        {
          attrs: {
            size: "normal",
            title: _vm.type === "download" ? "下载报表" : "预览报表",
            "z-index": 6,
          },
          on: { ok: _vm.handleOk, cancel: _vm.cancel },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { ref: "periodId", attrs: { label: "年月", prop: "periodId" } },
                [
                  _c("a-month-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请选择月份",
                      "disabled-date": _vm.disabledStartDate,
                      "value-format": "YYYY-MM",
                    },
                    model: {
                      value: _vm.form.periodId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "periodId", $$v)
                      },
                      expression: "form.periodId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "参保主体" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { mode: "multiple", placeholder: "请选择" },
                      model: {
                        value: _vm.form.contractCo,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "contractCo", $$v)
                        },
                        expression: "form.contractCo",
                      },
                    },
                    _vm._l(_vm.insureList, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.companyId,
                          attrs: { value: item.companyId },
                        },
                        [_vm._v(_vm._s(item.companyName))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }